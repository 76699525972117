.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cheatbot {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.cls-1 {
  fill: none;
}

.cls-2 {
  stroke: #21858f;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5px;
}

.cls-3 {
  fill: none;
  stroke: #20868f;
  stroke-width: 1.4px;
}

.cls-4 {
  fill: #ef7d77;
  stroke-width: 0px;
}

.cls-5 {
  fill: none;
  stroke-width: 3px;
  stroke: white;
}
